import EventBusyIcon from '@mui/icons-material/EventBusy';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Grid from '@mui/material/Grid2';
import { Box, Button, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonRendererProps, CameraButton } from '../../../component/camera';
import { ImageFile } from '../../../component/camera/ImageFile';
import { BasicDialog, DialogState } from '../../../component/dialog/BasicDialog';

export enum MissingCriteria {
  DATE = 'date',
  TOTAL = 'total',
}

interface Props {
  handleReuploadImage: (file: ImageFile, pdf?: Blob) => void;
  dialogState: DialogState;
  isLoading?: boolean;
  onSubmit: () => void;
  missingCriteria?: MissingCriteria;
}

interface MissingCriteriaOutput {
  icon: JSX.Element;
  title: string;
  description: string;
}

const MissingCriteriaModal: React.FC<Props> = ({ handleReuploadImage, dialogState, isLoading, onSubmit, missingCriteria }) => {
  const { t } = useTranslation('benefitModule');

  const UploadImageButton: React.FC<ButtonRendererProps> = useCallback(
    props => {
      return (
        <Button variant="contained" fullWidth {...props}>
          {t('benefit.LUNCH.warningModal.cancelButton')}
        </Button>
      );
    },
    [t],
  );

  const missingCriteriaData: MissingCriteriaOutput = useMemo(() => {
    switch (missingCriteria) {
      case MissingCriteria.DATE: {
        return {
          icon: <EventBusyIcon fontSize="large" color="primary" />,
          title: t('benefit.LUNCH.warningModal.missingDateTitle'),
          description: t('benefit.LUNCH.warningModal.missingDateDescription'),
        };
      }
      case MissingCriteria.TOTAL: {
        return {
          icon: <WarningAmberIcon fontSize="large" color="primary" />,
          title: t('benefit.LUNCH.warningModal.missingTotalTitle'),
          description: t('benefit.LUNCH.warningModal.missingTotalDescription'),
        };
      }
      default: {
        return { icon: <EventBusyIcon />, title: t('benefit.LUNCH.warningModal.missingDateTitle'), description: t('description') };
      }
    }
  }, [missingCriteria, t]);

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const reuploadImage = useCallback(
    (imageFile: ImageFile, pdf?: Blob) => {
      dialogState.handleClose();
      handleReuploadImage(imageFile, pdf);
    },
    [dialogState, handleReuploadImage],
  );

  return (
    <BasicDialog
      dialogState={dialogState}
      title=""
      hideCloseIcon
      maxWidth="xs"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      content={
        <Box>
          <Box textAlign="center">{missingCriteriaData.icon}</Box>
          <Typography variant="h5" textAlign={'center'} gutterBottom>
            {missingCriteriaData.title}
          </Typography>
          <Typography marginBottom={1} textAlign="center">
            {missingCriteriaData.description}
          </Typography>
        </Box>
      }
      actions={
        <Grid display="flex" flexDirection="column" gap={0.5}>
          <CameraButton buttonRenderer={UploadImageButton} onSelect={reuploadImage} />
          <Button
            variant="text"
            color="primary"
            onClick={handleSubmit}
            sx={{ marginBottom: 0.5 }}
            data-test-id="missing-criteria-continue-button"
            disabled={isLoading}
          >
            {t('benefit.LUNCH.warningModal.continueButton')}
          </Button>
        </Grid>
      }
    />
  );
};

export default MissingCriteriaModal;
